export default {
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d’inscription"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat en Gestion des projets et programmes de santé"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
  "nationalite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
  "pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Pays de résidence"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "diplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme obtenu le plus élevé"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du diplôme le plus élevé"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez brièvement vos expériences professionnelles pertinentes pour le programme (300 mots maximum)"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre ma candidature"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature envoyée"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre candidature a été soumise avec succès. Nous vous contacterons pour confirmer votre pré-sélection."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre site web"])},
  "buttonbase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler au programme"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifiez-vous en Gestion des projets et programmes de santé"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développez une expertise avérée en gestion des projets dans le secteur de la santé, avec notre programme à distance de certification professionnelle !"])},
  "objectifT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs & Compétences visées "])},
  "objectifP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme de formation vous permet de développer des compétences avancées en gestion de projets dans le domaine de la santé, afin de concevoir, planifier, exécuter, évaluer et ajuster efficacement des projets de santé publique, en optimisant l'utilisation des ressources et en maximisant l'impact sur la qualité des soins et le bien-être des populations"])},
  "objectifT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projets et programmes de santé"])},
  "objectifP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre et appliquer les concepts de base de la gestion de projet dans le secteur de la santé "])},
  "objectifT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planification stratégique"])},
  "objectifP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborer des plans stratégiques pour les projets de santé, en tenant compte des objectifs de santé publique"])},
  "objectifT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outils de gestion de projet"])},
  "objectifP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maîtriser les outils et logiciels spécifiques pour la gestion des projets de santé."])},
  "objectifT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des risques"])},
  "objectifP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier, d'évaluer et gérer les risques potentiels dans les projets ou programmes de santé."])},
  "objectifT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des ressources"])},
  "objectifP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développer des compétences essentielles en communication, finances et en gestion d'équipe."])},
  "objectifT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi-évaluation"])},
  "objectifP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concevoir et mettre en œuvre des systèmes de suivi et d'évaluation pour mesurer l'efficacité des projets de santé. "])},
  "publicsT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publics cibles & pré-requis"])},
  "publicsT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels de la santé"])},
  "publicsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins ; chirurgiens ; infirmiers ; sages-femmes ; pharmaciens; paramédicaux ; étudiants en sciences de la santé."])},
  "publicsT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres publics cibles"])},
  "publicsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnels des organisations non gouvernementales ou humanitaires ;gestionnaires de projets de santé, spécialistes du suivi-évaluation ; coordonnateurs de projets ; gestionnaires de programmes ; évaluateurs indépendants ; personnel des bailleurs de fonds chargé de mener et d'évaluer des projets de santé, responsables d’associations, travailleurs sociocommunautaires ...."])},
  "moyensT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens pédagogiques"])},
  "moyensP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme est basé sur une méthode pédagogique d’approche par compétences et s’insère parfaitement dans votre agenda sans bousculer votre vie professionnelle ou personnelle."])},
  "formatT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "formatP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cours sont dispensés à temps partiel, exclusivement à distance, en mode synchrone (classes virtuelles) et asynchrone (cours pré-enregistrés)."])},
  "comiteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité"])},
  "comiteP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le comité pédagogique de ce programme est composé d’experts internationaux reconnus en gestion des projets et programmes de santé."])},
  "dureeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "dureeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 heures de cours"])},
  "periodeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "periodeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une nouvelle cohorte est lancée à chaque début de "])},
  "evaluationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "evaluationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la fin du programme, l’apprenant(e) est soumis(e) à une épreuve de contrôle de connaissance en ligne au format QCM."])},
  "certificationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "certificationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme est sanctionné par un certificat international professionnel accrédité en « gestion des projets et programmes de santé », qui est délivré aux apprenant(e)s ayant obtenu une note supérieure ou égale à 60% au terme du contrôle des connaissances."])},
  "contenuT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du Programme"])},
  "droitInscriptionT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’inscription"])},
  "droitInscriptionTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Possibilité de paiements par tranches*"])},
  "droitInscriptionP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les droits d’inscription à la formation s’élèvent à"])},
  "droitInscriptionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inscription n’est considérée comme définitive qu’après règlement de ce montant auprès de l’organisme de formation médicale continue REMA, à condition que votre candidature soit acceptée. Places limitées !"])},
  "placeDisponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les places disponibles étant limitées pour ce programme de certification, seules les candidatures pertinentes seront retenues. Les candidats sélectionnés seront contactés par nos équipes afin de finaliser leur inscription."])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "renseignements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements"])},
  "entrepriseT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. Tous droits réservés."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialités"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])}
}